import { z } from 'nestjs-zod/z';
import { passwordValidator } from '../validators/password';

export const captchaSchema = z.object({
  gReCaptchaToken: z.string(),
});

export const signUpSchema = z.object({
  email: z
    .string()
    .nonempty('You have to provide the e-mail address')
    .email('Invalid email address'),
  password: passwordValidator,
});

export const signUpWithCaptchaSchema = signUpSchema.merge(captchaSchema);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISignUp extends z.infer<typeof signUpSchema> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISignUpWithCaptcha extends z.infer<typeof signUpWithCaptchaSchema> {}
