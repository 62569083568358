import { Injectable } from '@nestjs/common';
import { S3StorageAdapter } from './s3/s3-storage-adapter.service';
import {
  ICompleteMultipartUpload,
  ICompleteMultipartUploadParams,
  ICustomObjectMetadata,
  IInitiateMultipartUpload,
  IListFilesOutput,
  IListFilesParams,
} from './storage.interfaces';

@Injectable()
export class StorageFacade {
  constructor(private readonly adapter: S3StorageAdapter) {}

  public getTempfileDestinationUrl(
    path: string,
    options: ICustomObjectMetadata
  ) {
    return this.adapter.getTempfileDestinationUrl(path, options);
  }

  public moveFileToPermStorage(path: string, userId: string): Promise<string> {
    return this.adapter.moveFileToPermStorage(path, userId);
  }

  public getFileUrl(path: string): Promise<string> {
    return this.adapter.getFileUrl(path);
  }

  public listFiles(params: IListFilesParams): Promise<IListFilesOutput> {
    return this.adapter.listFiles(params);
  }

  public initiateMultipartUpload(
    path: string
  ): Promise<IInitiateMultipartUpload> {
    return this.adapter.initiateMultipartUpload(path);
  }
  public getMultipartPresignedUrl(
    path: string,
    uploadId: string,
    partNumber: number
  ): Promise<string> {
    return this.adapter.getMultipartPresignedUrl(path, uploadId, partNumber);
  }
  public completeMultipartUpload(
    params: ICompleteMultipartUploadParams
  ): Promise<ICompleteMultipartUpload> {
    return this.adapter.completeMultipartUpload(params);
  }
  public abortMultipartUpload(path: string, uploadId: string): Promise<void> {
    return this.adapter.abortMultipartUpload(path, uploadId);
  }
}
