import { z } from 'nestjs-zod/z';
import { signUpSchema } from '../custom-auth';

const sortBySchema = z.object({
  sortBy: z.array(z.tuple([z.string(), z.string()])).optional(),
});

const sortByStringSchema = z.object({
  sortBy: z.string().optional(),
});

export const paginateQueryWithoutSortingSchema = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  searchBy: z.array(z.string()).optional(),
  search: z.string().optional(),
  filter: z.record(z.union([z.string(), z.array(z.string())])).optional(),
  path: z.string().optional(),
});

const paginateQuerySchema = paginateQueryWithoutSortingSchema.merge(sortBySchema);
const paginateQueryWithSortByStringSchema =
  paginateQueryWithoutSortingSchema.merge(sortByStringSchema);

export type TPaginateQuery = z.infer<typeof paginateQuerySchema>;
export type TPaginateQueryWithSortByString = z.infer<typeof paginateQueryWithSortByStringSchema>;

export const getManyQueryParamsSchema = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  // TODO: add here ascending and descending cc: Chester.
  sortBy: z.array(z.tuple([z.string(), z.string()])).optional(),
  searchBy: z.array(z.string()).optional(),
  search: z.string().optional(),
  filter: z.record(z.union([z.string(), z.array(z.string())])).optional(),
  select: z.array(z.string()).optional(),
  path: z.string().optional(),
});
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGetManyQueryParams extends z.infer<typeof getManyQueryParamsSchema> {}
