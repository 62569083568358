import { z } from 'nestjs-zod/z';

export interface IAccessToken {
  accessToken: string | undefined;
}

export const getAccessTokenSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export type TGetAccessToken = z.infer<typeof getAccessTokenSchema>;
