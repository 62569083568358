import { createZodDto } from 'nestjs-zod/dto';
import {
  facebookLoginResponseSchema,
  facebookLoginSchema,
  facebookMobileLoginSchema,
} from './facebook-login.schema.type';

export class FacebookLoginDto extends createZodDto(facebookLoginSchema) {}
export class FacebookMobileLoginDto extends createZodDto(facebookMobileLoginSchema) {}
export class FacebookLoginResponseDto extends createZodDto(facebookLoginResponseSchema) {}
