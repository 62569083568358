import { ZodIssue } from 'nestjs-zod/z';

export class DataValidationErrors extends Error {
  public formError: ZodIssue[];

  constructor(formError: ZodIssue[]) {
    super('Invalid Form');
    this.formError = formError;
  }
}
