import { EPaymentType } from './enums';

export enum EResourceAccessStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type IProductItem = {
  id: string;
  name: string;
  price: {
    id: string;
    amount: number | null;
    currency: string;
    type: EPaymentType;
  };
};
