import { z } from 'nestjs-zod/z';

export const extendedUserSchema = z.object({
  cognitoId: z.string(),
  email: z.string(),
  firstName: z.nullable(z.string()),
  lastName: z.nullable(z.string()),
  avatarUrl: z.nullable(z.string()),
  description: z.nullable(z.string()),
});
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IExtendedUser extends z.infer<typeof extendedUserSchema> {}
