import { z } from 'nestjs-zod/z';

export const updatedUserSchema = z.object({
  cognitoId: z.string(),
  email: z.string(),
  firstName: z.nullable(z.string()),
  lastName: z.nullable(z.string()),
  avatarKey: z.nullable(z.string()),
  description: z.nullable(z.string()),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type TUpdatedUser = z.infer<typeof updatedUserSchema>;
