import { z } from 'nestjs-zod/z';

export const eventSchema = z.object({
  id: z.string(),
  type: z.string(),
  details: z.object({
    email: z.string(),
  }),
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEvent extends z.infer<typeof eventSchema> {}
