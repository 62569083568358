export enum RevenueCatEventType {
  INITIAL_PURCHASE = 'INITIAL_PURCHASE',
  RENEWAL = 'RENEWAL',
  CANCELLATION = 'CANCELLATION',
  EXPIRATION = 'EXPIRATION',
  UNCANCELLATION = 'UNCANCELLATION',
  NON_RENEWING_PURCHASE = 'NON_RENEWING_PURCHASE',
  SUBSCRIPTION_PAUSED = 'SUBSCRIPTION_PAUSED',
  BILLING_ISSUE = 'BILLING_ISSUE',
}
