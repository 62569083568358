import { z } from 'nestjs-zod/z';

export const updateUserSchema = z.object({
  email: z.string().email().optional(),
  firstName: z.string({ invalid_type_error: 'First name must be a string' }).nullish(),
  lastName: z.string({ invalid_type_error: 'Last name must be a string' }).nullish(),
  avatarKey: z.string().nullish(),
  description: z.string().max(200, 'Description should have max 200 characters').nullish(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateUser extends z.infer<typeof updateUserSchema> {}
