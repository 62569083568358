import { isBackendSdkResponseError } from './is-backend-sdk-response-error';
import { HttpError } from '@nestia/fetcher';

export function isFormValidationSdkResponseError(error: unknown): error is HttpError {
  if (!isBackendSdkResponseError(error)) return false;

  const dataData = error.toJSON<HttpError>();

  return (
    dataData.status === 400 &&
    'message' in dataData.message &&
    Array.isArray(dataData.message.message)
  );
}
