import { ListObjectsV2Output } from '@aws-sdk/client-s3';
import { IListFilesOutput } from '../storage.interfaces';

export const mapListFilesOutput = (
  data: ListObjectsV2Output
): IListFilesOutput => ({
  data: (data.Contents || []).map((item) => ({
    lastModified: item.LastModified!,
    key: item.Key!,
    size: item.Size!,
  })),
  metadata: {
    nextToken: data.NextContinuationToken,
    itemsCount: data.KeyCount,
    maxItems: data.MaxKeys,
    prefix: data.Prefix,
  },
});
