import { z } from 'nestjs-zod/z';
import { RevenueCatEventType } from './enums';

export const getMobilePaymentHistorySchema = z.object({
  id: z.string(),
  event: z.nativeEnum(RevenueCatEventType),
  externalId: z.string(),
  productId: z.string(),
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TGetMobilePaymentHistory extends z.infer<typeof getMobilePaymentHistorySchema> {}
